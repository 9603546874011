@import "scss/mixins/material-palette";
@import "scss/mixins/skinning";
@import "scss/variables";
@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "scss/theming";
@import "scss/custom-bootstrap";
@import "scss/core";

.blinking {
  animation: blinkingText 2.0s infinite;
}

@keyframes blinkingText {
  100% {
    color: #FF0000;
  }
  20% {
    color: transparent;
  }
}
// TODO: remove after adding new image-viewer
//.image-gallery-2 {
//  width: 400px !important;
//  position: relative !important;
//  height: 305px !important;
//  margin-top: 45px !important;
//  margin-bottom: 0px !important;
//}
